.FlexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.FlexContainerInner {
  width: 95%;
}

.table {
  width: 100% !important;
  margin: 25px 0px;
  /* height: auto !important; */
  box-shadow: 1px 1px 15px -6px #0005;
  border-radius: 7px;
}

@media only screen and (max-width: 600px) {
  .FlexContainerInner {
    width: 95%;
  }
}

.subHeader {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.pagination-wrapper {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.subHeader svg {
  margin: 16px;
}

.subHeaderLabel {
  font-size: 14px;
  font-weight: bold;
  color: #aaa;
  margin-right: 15px;
}

.table-row .rs-table-cell-content {
  text-align: left !important;
}
