.AddRoute {
  width: 100%;
  /* height: calc(100vh - 45px); */
  position: relative;
  background-color: #fff;
}

.header {
  width: 100%;
  padding: 15px 15px;
  align-items: center;
  border-bottom: 1px solid #0001;
  background-color: #fdfefe;
  box-shadow: 0px 1px 5px -3px #0008;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

.back-btn {
  margin-right: 10px;
}

.header_text {
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.w-100 {
  width: 100%;
}

.CreateRoute-inner {
  width: 100%;
  height: 100%;
  padding: 15px;
  justify-content:center;
  align-items: flex-start;
  padding-bottom: 100px;
}

.inputFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.inputFlex .rs-form-control-wrapper {
  width: 76%;
}

.inputFlex-input {
  border: none;
  border-bottom: 1px solid #0005;
  border-radius: 0px;
  width: 100%;
}
.inputFlex-input2 {
  border: none;
  border-bottom: 1px solid #0005;
  border-radius: 0px;
  width: 70%;
}
.inputFlex-input2 .rs-input {
  border: 1px solid #e5e5ea00;
}
.rs-auto-complete .rs-input{
  border: 1px solid #e5e5ea00;
}
.btn {
  border-radius: 3px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 8px -7px #000;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: bold;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #0002;
  padding: 15px 0px
}

.flexOrder {
  font-size: 16px;
  font-weight: bold;
  color: #0007;
}

.route_column {
  display: flex; 
  flex: 1;
  flex-direction: column;
  /* width: 50%; */
}

.textFlex {
  font-size: 14px;
  /* font-weight: bold; */
  color: #000;
}
.route-leads .swipeable-list{
  flex: auto !important;
}
.route-leads {
  width: 100%;
}