.profile {
  width: 100%;
  height: calc(100vh - 45px );
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 20px;
}

.profile-image {
  background: rgb(237, 250, 225);
  color: rgb(76, 175, 80);
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
  border-radius: 50%;
  margin-left: 15px;
}

.profile-name {
  font-size: 16px;
  font-weight: 600;
  color: #444e;
}

.profile-email {
  margin-top: 3px;
  margin-bottom: 25px;
}

.logout-btn {
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 8px -7px #000;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: bold;
}

.homebtn {
  margin: 0px 0px 0px;
  border-radius: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #0001;
  border-bottom: 1px solid #aaa1;
  background-color: #fff;
  padding-left: 25px ;
}

.homebtn .homebtnIcon {
  margin-right: 10px
}
.loading-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.rbc-btn-group{
  margin: auto;
  width: 100%;
  text-align: center;
}
.profile-Horizontal-Scroll {
  width: 100vw;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.profile-Horizontal-Scroll-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 15px;
  border-radius: 50px;
  margin: 3px 10px;
  /* overflow: auto; */
  width: 170px;
  box-shadow: 0px 1px 7px -3px #0005;
}

.profile-Horizontal-Scroll-items-inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.profile-Horizontal-Scroll-items-inner-title {
  font-size: 16px;
  font-weight: 600;
  color: #444e !important;
  white-space: nowrap;
}

.profile-Horizontal-Scroll-items-inner-subtitle {
  font-size: 12px;
  font-weight: 600;
  color: #4448 !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.camera-icon{
  position: absolute;
  bottom: 10px;
  font-size: 20px !important;
}
#avatar-input{
  display: none;
}