.routes {
  width: 100%;
  position: relative;
}

.routes-item {
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #eee ;
  height: 80px;
}

.routes-item-delete {
  width: 100%;
  background-color: #f00;
  padding: 20px;
  text-align: right;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.routes-item-delete-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;

}

.routes-item-row {
  width: 100%;
}

.routes-item-header {
  color: #000e;
  font-size: 16px;
  font-weight: bold;
}

.routes-item-sub {
  color: #888;
  font-size: 12px;
  margin-top: 2px
}

.routes-item-sub-colored {
  color: #1675e0aa !important;
  font-size: 12px;
  text-vertical-align: middle;
  margin-top: 2px
}