.AddLeads {
  width: 100%;
  position: relative;
  background-color: #fff;
}

.w-100 {
  width: 100%;
}

.row {
  padding: 10px 15px;
  align-items: center;
}

.header {
  width: 100%;
  padding: 15px 15px;
  align-items: center;
  border-bottom: 1px solid #0001;
  background-color: #fdfefe;
  box-shadow: 0px 1px 5px -3px #0008;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

.back-btn {
  margin-right: 10px;
}

.header_text {
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.w-100 {
  width: 100%;
}

.AddLeadsInner {
  padding: 15px;
  width: 100%;
}

.subHeaderTextAddLeads {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ccc;
  border-bottom: 1px solid #0001;
}

.status-wrapper {
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
}
