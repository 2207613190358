.root {
    color: #000 !important;
    --color: #000 !important;
}
.rs-btn-default.rs-btn-blue {
    background-color: #1F4690 !important;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-color: #1F4690 !important;
    background: #1F4690 !important;
}
.rs-btn-primary {
    background-color: #1F4690 !important;
}