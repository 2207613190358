.lead-status-img{
    width: 28px;
    height: 28px;
    margin-top: -5px
}
.bg-primary {
    background-color: #1F4690;
}
.filterMenu {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }