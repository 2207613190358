.haeder {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #0005;
  width: 100%;
  display: block;
  border-bottom: 1px solid #0002;
}

.text {
  width: 100%;
  color: #000;
  padding-top: 7px;
  padding-bottom: 10px;
  border-bottom: 1px solid #0002;
  display: block;
  height: 35px;
}

.text-muted {
  color: #0005;
}

.text-right {
  text-align: right !important;
}
