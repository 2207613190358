.rs-picker-toggle-wrapper {
  border: none;
  border-bottom: 1px solid #e5e5ea;
  width: 100%;
}

.status-wrapper {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap !important;
}

.admin-tag-btn-wrapper {
  margin-bottom: 15px;
}