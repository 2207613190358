.UploadModalBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 65px;
    border: 1px dashed #0005;
    border-radius: 10px;
  }
  .uploader_icon {
    width: 50px;
    margin-bottom: 10px;
    margin: auto;
  }
  .uploaderText {
    font-size: 16px;
    color: #1F4690 !important;
  }
  .btn-green-upload {
    color: #fff;
    font-size: 0.7rem;
    background: #1F4690;
    padding: 0px 5px;
    border-radius: 0 0.25rem 0.25rem 0;
    margin: 0;
    vertical-align: top;
    height: 40px;
    height: 27px !important;
    margin: 4px;
    border-radius: 3px !important;
    overflow: hidden;
    margin-left: 8%;
    padding: 5px 10px;
    font-size: 14px;
  }  
  @media only Screen and (max-width: 500px) {
    .UploadModalBtn {
      position: relative;
      left: 23px;
      bottom: 20px;
    }
  }
  @media only screen and (max-width: 575px) {
    .mw-100 {
      width: 100% !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: -20px;
      margin-bottom: 0;
      margin-top: 6px;
      margin-right: 10px;
    }
    .btn-green-upload {
        font-size: 14px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -86px -13px;
        position: absolute;
        top: -5px;
        left: 0.5rem;
      }
  }