.mainView-Content {
  --background: #fff !important;
  color: #222 !important;
  --color: #222 !important;
}

ion-text {
  color: #222 !important;
}

/* DOES NOT WORK - not specific enough */
.alert-wrapper {
  background: #e5e5e5;
}

/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .alert-wrapper {
  background: #e5e5e5;
}
.my-css {
  --background: #e5e5e5;
  color: #000 !important;
}

.my-css h2 {
    color: #000 !important;
}
