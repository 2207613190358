.navItems {
  flex: 1;
}
.rs-nav-item {
  width: 100%;
}

.rs-nav-item > .rs-nav-item-content {
  text-align: center;
  padding: 14px 12px;
}

.navItems.active .rs-nav-item > .rs-nav-item-content {
  color: #1F4690 !important;
}

.navItems.active .rs-nav-item > .rs-nav-item-content::before {
  width: 100%;
  left: 0 !important;
  right: 0 !important;
  transition: left 0.3s ease-out, right 0.3s ease-out;

  background-color: #1F4690 !important;
  height: 4px !important;
}

.navMain {
  background-color: #fff;
  box-shadow: 0px -1px 5px -3px #0008;
  z-index: 9999;
  border-top: 1px solid #0001; 
}
