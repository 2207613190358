.admin-header {
  padding: 7px 10px;
  border-bottom: 1px solid #0001;
  background-color: #fcfcfc;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px -3px #0007;
}

.admin-header-text {
  margin-left: 15px;
  font-size: 18px;
  color: #222;
  font-weight: bold;
}

.admin-header-menuIcon {
  font-size: 18px;
}

.MenuTooglleButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-nav.rs-nav-vertical .rs-dropdown {
  border: 1px solid #fafafa;
}
.rs-drawer-content{
  overflow-y: scroll;
}
