.select-box{
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
}
.text-success {
    color: darkgreen;
}
.text-danger {
    color: #f00;
}