.home {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.map {
  width: 100%;
  height: 100%;
  z-index: 99;
}
#map_canvas{
  height: 100vh;
  width: 100vw;
  position: inherit !important;
}
.home-floating-btns {
  position: absolute;
  top: 60px;
  left: 15px;
  z-index: 999;
}

.home-floating-btns-bottom {
  position: absolute;
  bottom: 65px;
  left: 15px;
  z-index: 999;
}

.home-floating-modal {
  width: 100%;
  height: 100%;
  background-color: #0005;
  z-index: 99999;
  position: absolute;
  left: 0;
  top: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: 0.1s
}

.home-floating-modal.open {
  transition: 0.1s;
  top: 0vh;
}

.home-floating-modal-body {
  width: 100%;
  padding: 0px 15px;
  background-color: #fff;
  border-radius: 15px 15px 0 0;
}

.home-floating-modal-row {
  width: 100%;
  padding: 10px 0px;
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
}

.tag-btn {
  margin: 10px 10px 5px 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-modal {
  margin: 0 10px 20px;
  border-radius: 3px;
  box-shadow: 0px 2px 8px -7px #000;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: bold;
}

.tag-btn.uc {
  background-color: #db7b1a !important;
}
.tag-btn.ni {
  background-color: #000 !important;
}
.tag-btn.os {
  background-color: #541654 !important;
}
.tag-btn.ingl {
  background-color: #ef42f5 !important;
}
.tag-btn.rent {
  background-color: #42a4f5 !important;
}
.tag-btn.r {
  background-color: #48f542 !important;
}
.tag-btn.cita {
  background-color: #b8327f !important;
}
.tag-btn.apen {
  background-color: #858585 !important;
}
.tag-btn.demo {
  background-color: #4200b5 !important;
}
.tag-btn.win {
  background-color: #e3dd34 !important;
}
.tag-btn.nho {
  background-color: #7557ad !important;
}
.tag-btn.sm {
  background-color: #ff8800 !important;
}
.tag-btn.ndg {
  background-color: #ff1271 !important;
}
.tag-btn.mycl {
  background-color: #ffa569 !important;
}
.tag-btn.cxl {
  background-color: #e33434 !important;
}
.tag-btn.inst {
  background-color: #964B00 !important;
}
.tag-btn.po {
  background-color: #545454 !important;
}
.tag-btn.nm {
  background-color: #11a61f !important;
}

/* DOES NOT WORK - not specific enough */
.alert-wrapper {
  background: #e5e5e5;
}

/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .alert-wrapper {
  background: #e5e5e5;
}
.my-custom-class {
  --background: #e5e5e5;
}
#markerLayer img {
  border-radius: 50%;
}