.logincontainer {
  padding: 15px;
  background-color: #fcfcfc;
  flex: 1;
  height: 100vh;
  align-items: center;
}

.loginCard {
  padding: 25px 18px 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 100px 0px #0001;
  width: 326px;
}

.header_text_login {
  color: #444;
  margin-bottom: 40px !important;
  text-shadow: 1px 1px #fff, -1px -1px #4443;
  font-size: 45px
}

.inputLable {
  color: #999;
}

.input {
  border: none;
  border-bottom: 1px solid #0005;
  border-radius: 0px;
  width: 100%
}

.rs-form-control-wrapper {
  width: 100%;
}

.rs-form-control-wrapper > .rs-input {
  width: 100%;
}

.btn-login {
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 8px -7px #000;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: bold;
}

.btn-forgot {
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  font-size: 12px;
  letter-spacing: 0.001em;
  background-color: #fff;
  text-decoration: underline;
}
.text-danger{
  color: #f00 !important;
  text-transform: capitalize;
  text-align: center;
}